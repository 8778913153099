<template>
  <v-card class="mx-auto" :loading="loading > 0" :disabled="loading > 0">
    <toolbar
        :path="path"
        :storages="storagesArray"
        :storage="activeStorage"
        :endpoints="endpoints"
        :axios="axiosInstance"
        v-on:storage-changed="storageChanged"
        v-on:path-changed="pathChanged"
        v-on:add-files="addUploadingFiles"
        v-on:loading="loadingChanged"
        v-on:delete="deleteFiles"
        v-on:rename="rename"
        v-on:move="move"
        v-on:folder-created="refreshPending = true"
    ></toolbar>
    <v-row no-gutters>
      <v-col v-if="tree && $vuetify.breakpoint.smAndUp" sm="auto" class="limitHeight">
        <tree
            ref="tree"
            :path="path"
            :storage="activeStorage"
            :icons="icons"
            :endpoints="endpoints"
            :axios="axiosInstance"
            :refreshPending="refreshPending"
            v-on:path-changed="pathChanged"
            v-on:loading="loadingChanged"
            v-on:refreshed="refreshPending = false"
        ></tree>
      </v-col>
      <v-divider v-if="tree" vertical></v-divider>
      <v-col class="limitHeight">
        <list
            ref="list"
            :path="path"
            :icons="icons"
            :storage="activeStorage"
            :endpoints="endpoints"
            :axios="axiosInstance"
            :refreshPending="refreshPending"
            v-on:path-changed="pathChanged"
            v-on:loading="loadingChanged"
            v-on:refreshed="refreshPending = false"
            v-on:refresh="refreshPending = true"
        ></list>
      </v-col>
    </v-row>
    <upload
        v-if="uploadingFiles.length"
        :path="path"
        :storage="activeStorage"
        :files="uploadingFiles"
        :icons="icons"
        :axios="axiosInstance"
        :endpoint="endpoints.upload"
        :maxUploadFilesCount="maxUploadFilesCount"
        :maxUploadFileSize="maxUploadFileSize"
        v-on:add-files="addUploadingFiles"
        v-on:remove-file="removeUploadingFile"
        v-on:clear-files="uploadingFiles = []"
        v-on:cancel="uploadingFiles = []"
        v-on:uploaded="uploaded"
    ></upload>
  </v-card>
</template>

<script>
import axios from "axios";

import Toolbar from "./Toolbar.vue";
import Tree from "./Tree.vue";
import List from "./List.vue";
import Upload from "./Upload.vue";

const availableStorages = [
  {
    name: "Local",
    code: "local",
    icon: "mdi-folder-multiple-outline"
  },
  {
    name: "Amazon S3",
    code: "s3",
    icon: "mdi-amazon-drive"
  }
];

const endpoints = {
  list: { url: "/storage/{storage}/list?path={path}", method: "get" },
  upload: { url: "/storage/{storage}/upload?path={path}", method: "post" },
  download: { url: "/storage/{storage}/download?path={path}", method: "get" },
  get: { url: "/storage/{storage}/get?path={path}", method: "get" },
  move: { url: "/storage/{storage}/move", method: "post" },
  mkdir: { url: "/storage/{storage}/mkdir?path={path}", method: "post" },
  delete: { url: "/storage/{storage}/delete?path={path}", method: "post" },
  invalidate: { url: "/storage/{storage}/invalidate", method: "post" },
  lastFlush: { url: `/storage/{storage}/lastFlush`, method: "get" },
};

const fileIcons = {
  zip: "mdi-folder-zip-outline",
  rar: "mdi-folder-zip-outline",
  htm: "mdi-language-html5",
  html: "mdi-language-html5",
  js: "mdi-nodejs",
  json: "mdi-code-json",
  md: "mdi-markdown",
  pdf: "mdi-file-pdf",
  png: "mdi-file-image",
  jpg: "mdi-file-image",
  jpeg: "mdi-file-image",
  mp4: "mdi-filmstrip",
  mkv: "mdi-filmstrip",
  avi: "mdi-filmstrip",
  wmv: "mdi-filmstrip",
  mov: "mdi-filmstrip",
  txt: "mdi-file-document-outline",
  xls: "mdi-file-excel",
  other: "mdi-file-outline"
};

export default {
  components: {
    Toolbar,
    Tree,
    List,
    Upload
  },
  model: {
    prop: "path",
    event: "change"
  },
  props: {
    // comma-separated list of active storage codes
    storages: {
      type: String,
      default: () => availableStorages.map(item => item.code).join(",")
    },
    // code of default storage
    storage: { type: String, default: "local" },
    // show tree view
    tree: { type: Boolean, default: true },
    // file icons set
    icons: { type: Object, default: () => fileIcons },
    // custom backend endpoints
    endpoints: { type: Object, default: () => endpoints },
    // custom axios instance
    axios: { type: Function },
    // custom configuration for internal axios instance
    axiosConfig: { type: Object, default: () => {} },
    // max files count to upload at once. Unlimited by default
    maxUploadFilesCount: { type: Number, default: 0 },
    // max file size to upload. Unlimited by default
    maxUploadFileSize: { type: Number, default: 0 }
  },
  data() {
    return {
      loading: 0,
      path: "",
      activeStorage: null,
      uploadingFiles: [],
      refreshPending: false,
      axiosInstance: null
    };
  },
  computed: {
    storagesArray() {
      let storageCodes = this.storages.split(","),
          result = [];
      storageCodes.forEach(code => {
        result.push(availableStorages.find(item => item.code === code));
      });
      return result;
    }
  },
  methods: {
    loadingChanged(loading) {
      if (loading) {
        this.loading++;
      } else if (this.loading > 0) {
        this.loading--;
      }
    },
    storageChanged(storage) {
      this.activeStorage = storage;
    },
    addUploadingFiles(files) {
      files = Array.from(files);

      if (this.maxUploadFileSize) {
        files = files.filter(
            file => file.size <= this.maxUploadFileSize
        );
      }

      if (this.maxUploadFilesCount && this.uploadingFiles.length + files.length > this.maxUploadFilesCount) {
        files = files.slice(0, this.maxUploadFilesCount - this.uploadingFiles.length);
      }

      this.uploadingFiles.push(...files);
    },
    removeUploadingFile(index) {
      this.uploadingFiles.splice(index, 1);
    },
    uploaded() {
      this.uploadingFiles = [];
      this.refreshPending = true;
    },
    pathChanged(path) {
      this.path = path;
      this.$emit("change", path);
    },
    deleteFiles(path) {
      this.$refs.list.deleteItem({ basename: path.split(/[\\/]/).pop(), path });
    },
    rename(path) {
      this.$refs.list.rename(path);
    },
    move(path) {
      this.$refs.list.move(path, this.$refs.tree.items);
    }
  },
  created() {
    this.activeStorage = this.storage;
    this.axiosInstance = this.axios || axios.create(this.axiosConfig);
  },
  mounted() {
    if (!this.path && !(this.tree && this.$vuetify.breakpoint.smAndUp)) {
      this.pathChanged("/");
    }
  }
};
</script>

<style lang="scss">
.browser-wrapper > div > div.v-card__progress {
  height: 4px !important;
}

.limitHeight {
  .v-card__text {
    overflow-y: auto;
    max-height: 581px;
  }
  .v-treeview {
    overflow-y: auto;
    max-height: 570px;
  }
}
</style>