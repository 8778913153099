<template>
  <v-card flat tile min-height="380" max-height="800" max-width="1200" width="100%" class="d-inline-flex flex-column">
    <confirm ref="confirm"></confirm>
    <rename-modal ref="renameModal"></rename-modal>
    <move-modal ref="moveModal"></move-modal>
    <v-card-text
        v-if="!path"
        class="grow d-flex justify-center align-center grey--text"
    >Select a folder or a file</v-card-text>
    <v-card-text v-else-if="isFile" class="justify-top align-center">
        <a :href="formUrl(path)" target="_blank">{{ formUrl(path) }}</a><br>
        <span>Click download button on the right if it's not public (Access Denied)</span>
    </v-card-text>
    <v-card-text v-if="isImage" class="justify-center align-center">
      <v-img :src="formUrl(path)"></v-img>
    </v-card-text>
    <v-card-text v-if="path.match(/\.(mp4|webm|ogv)$/i)" class="justify-center align-center">
      <video :src="formUrl(path)" controls style="max-width: 800px">
        Unsupported video format
      </video>
    </v-card-text>
    <v-card-text v-if="path.match(/\.(txt|xml|html|csv)$/i)" class="justify-center align-center">
      <v-textarea v-model="content"/>
      <v-btn @click="save">Save</v-btn>
    </v-card-text>
    <v-card-text v-if="isJSON" class="justify-center align-center">
      <vue-json-editor v-model="content" :show-btns="true" :expandedOnStart="true" @json-save="save"/>
    </v-card-text>
    <v-card-text v-else-if="dirs.length || files.length" class="grow">
      <v-list subheader v-if="dirs.length">
        <v-subheader>Folders</v-subheader>
        <v-list-item
            v-for="item in dirs"
            :key="item.basename"
            @click="changePath(item.path)"
            class="pl-0"
        >
          <v-list-item-avatar class="ma-0">
            <v-icon>mdi-folder-outline</v-icon>
          </v-list-item-avatar>
          <v-list-item-content class="py-2">
            <v-list-item-title v-text="item.basename"></v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click.stop="deleteItem(item)">
              <v-icon color="grey lighten-1">mdi-delete-outline</v-icon>
            </v-btn>
            <v-btn icon v-if="false">
              <v-icon color="grey lighten-1">mdi-information</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-divider v-if="dirs.length && files.length"></v-divider>
      <v-row v-show="onlyImageFolder">
        <v-col
            v-for="item in files"
            :key="item.basename"
            class="d-flex child-flex"
            cols="2"
            style="padding-left: 20px"
        >
          <v-card @click="changePath(item.path)">
            <v-img
                :src="`https://game-translations.gamingcorpscasino.com${item.path}`"
                aspect-ratio="1"
                class="grey lighten-2"
                width="auto"
                height="120"
            ></v-img>
            <v-list-item-content style="padding: 10px">
              <v-list-item-title v-text="item.basename"></v-list-item-title>
              <v-list-item-subtitle>{{ formatBytes(item.size) }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn @click.stop="deleteItem(item)" style="font-size: 12px;">
                Delete
                <v-icon color="grey lighten-1">mdi-delete-outline</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-card>
        </v-col>
      </v-row>
      <v-list subheader v-if="files.length">
        <v-subheader>Files</v-subheader>
        <v-list-item
            v-for="item in files"
            :key="item.basename"
            @click="changePath(item.path)"
            class="pl-0"
        >
          <v-list-item-avatar class="ma-0">
            <v-icon>{{ icons[item.extension.toLowerCase()] || icons['other'] }}</v-icon>
          </v-list-item-avatar>

          <v-list-item-content class="py-2">
            <v-list-item-title v-text="item.basename"></v-list-item-title>
            <v-list-item-subtitle>{{ formatBytes(item.size) }}</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn icon @click.stop="deleteItem(item)">
              <v-icon color="grey lighten-1">mdi-delete-outline</v-icon>
            </v-btn>
            <v-btn icon v-if="false">
              <v-icon color="grey lighten-1">mdi-information</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-card-text v-else-if="filter" class="grow d-flex justify-center align-center grey--text py-5">
      No files or folders found
    </v-card-text>
    <v-card-text v-if="path && isDir && !files.length && !dirs.length"
        class="grow d-flex justify-center align-center grey--text py-5"
    >The folder is empty</v-card-text>
    <v-divider v-if="path"></v-divider>
    <v-toolbar v-if="path && isDir" dense flat class="shrink">
      <v-text-field
          solo
          flat
          hide-details
          label="Filter"
          v-model="filter"
          prepend-inner-icon="mdi-filter-outline"
          class="ml-n3"
      ></v-text-field>
      <v-btn icon v-if="false">
        <v-icon>mdi-eye-settings-outline</v-icon>
      </v-btn>
      <v-btn icon @click="load">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-toolbar>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import vueJsonEditor from 'vue-json-editor';
import { formatBytes } from '@/plugins/util';
import Confirm from './Confirm.vue';
import MoveModal from '@/components/MoveModal';
import RenameModal from '@/components/RenameModal';

export default {
  props: {
    icons: Object,
    storage: String,
    path: String,
    endpoints: Object,
    axios: Function,
    refreshPending: Boolean
  },
  components: {
    Confirm,
    MoveModal,
    RenameModal,
    vueJsonEditor
  },
  data() {
    return {
      items: [],
      filter: '',
      content: '',
    };
  },
  computed: {
    ...mapState([
      'bucket'
    ]),
    dirs() {
      return this.items.filter(
        item => item.type === "dir" && item.basename.includes(this.filter)
      );
    },
    files() {
      return this.items.filter(
          item => item.type === "file" && item.basename.includes(this.filter)
      );
    },
    isDir() {
      return this.path[this.path.length - 1] === "/";
    },
    isFile() {
      return !this.isDir;
    },
    isImage() {
      return this.isFile && this.path.match(/\.(jpg|jpeg|png|gif|svg|webp)$/i);
    },
    isJSON() {
      return this.path.match(/\.(json)$/i)
    },
    onlyImageFolder() {
      return this.items.filter(item => {
        return item.path.match(/\.(jpg|jpeg|png|gif|svg|webp)$/i)
      }).length === this.items.length;
    }
  },
  methods: {
    formatBytes,
    formUrl(path) {
      return `https://game-translations.gamingcorpscasino.com${path}`;
    },
    changePath(path) {
      this.$emit("path-changed", path);
    },
    async load() {
      this.$emit("loading", true);

      if (this.isDir) {
        let url = this.endpoints.list.url
            .replace(new RegExp("{storage}", "g"), this.storage)
            .replace(new RegExp("{path}", "g"), this.path);

        let config = {
          url,
          method: this.endpoints.list.method || "get"
        };

        let response = await this.axios.request(config);
        this.items = response.data;
      } else {
        if (this.path.match(/\.(txt|xml|html|csv|json)$/i)) {
          const url = this.endpoints.get.url
              .replace(new RegExp('{storage}', 'g'), this.storage)
              .replace(new RegExp('{path}', 'g'), this.path);

          const res = await this.axios.request({ url, method: this.endpoints.get.method || 'get' });
          this.content = res.data;
        }
      }

      this.$emit("loading", false);
    },
    async save() {
      const url = this.endpoints.upload.url
          .replace(new RegExp('{storage}', 'g'), this.storage)
          .replace(new RegExp('{path}', 'g'), this.path);

      try {
        await this.axios.post(url, {content: this.isJSON ? JSON.stringify(this.content) : this.content});
        this.$toast.open({
          message: '✓ Changes saved',
          type: 'success'
        });
      } catch (e) {
        this.$toast.open({
          message: 'Something went wrong!',
          type: 'error'
        });
      }
    },
    async deleteItem(item) {
      let confirmed = await this.$refs.confirm.open(
          "Delete",
          `Are you sure<br>you want to delete this ${
              this.isDir ? "folder" : "file"
          }?<br><em>${item.basename}</em>`
      );

      if (confirmed) {
        this.$emit("loading", true);

        let path = item.path;
        if (path.length < 2) return;

        const url = this.endpoints.delete.url
            .replace(new RegExp("{storage}", "g"), this.storage)
            .replace(new RegExp("{path}", "g"), item.path);

        const config = {
          url,
          method: this.endpoints.delete.method || "post"
        };

        await this.axios.request(config);

        this.$emit("loading", false);

        if (this.isDir && path.charAt(path.length - 1) === '/') {
          path = path.slice(0, -1);
        }

        setTimeout(() => {
          this.changePath(path.substring(0, path.lastIndexOf('/')) + '/');
          this.$emit("refresh");
        }, 500);
      }
    },
    async rename(path) {
      const basename = path.split(/[\\/]/).pop();
      const newName = await this.$refs.renameModal.open(basename);

      if (newName) {
        await this.performMove(path, path.replace(basename, newName));
      }
    },
    async move(path, items) {
      items = this.filterFolders(items);

      let newPath = await this.$refs.moveModal.open(path, items);
      if (!newPath) return;

      newPath += path.split(/[\\/]/).pop();

      if (path !== newPath) {
        await this.performMove(path, newPath);
      }
    },
    async performMove(from, to) {
      this.$emit("loading", true);

      let url = this.endpoints.move.url
          .replace(new RegExp("{storage}", "g"), this.storage);

      await this.axios.post(url, { from, to });
      const oldPath = from.substring(0, from.lastIndexOf('/')) + '/';
      const newPath = to.substring(0, to.lastIndexOf('/')) + '/';

      this.$emit("loading", false);

      setTimeout(() => {
        this.changePath(oldPath.length < newPath.length ? oldPath : newPath);
        this.$emit("refresh");
      }, 500);
    },
    filterFolders(items, filtered = []) {
      for (const item of items) {
        if (item.type === 'dir') {
          filtered.push(item.path);
        }

        if (item.children && item.children.length) {
          this.filterFolders(item.children, filtered);
        }
      }

      return filtered;
    }
  },
  watch: {
    async path() {
      this.items = [];
      await this.load();
    },
    async refreshPending() {
      if (this.refreshPending) {
        await this.load();
        this.$emit("refreshed");
      }
    }
  }
};
</script>

<style lang="scss">
.v-card {
  height: 100%;
}
.jsoneditor-tree {
  background: white !important;
}

.jsoneditor-btns {
    position: absolute;
    bottom: 100px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
</style>