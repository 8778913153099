<template>
  <div class="browser-wrapper">
    <file-browser storages="s3" storage="s3" :axiosConfig="{ baseURL }"
                  :key="bucket" :endpoints="endpoints" path="/" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import FileBrowser from '../components/FileBrowser';

export default {
  name: 'CloudManager',
  components: {
    FileBrowser
  },
  computed: {
    ...mapState([
      'bucket'
    ]),
    baseURL() {
      return process.env.VUE_APP_BACKEND_URL;
    },
    endpoints() {
      return {
        list: { url: `/storage/${this.bucket}/list?path={path}`, method: "get" },
        upload: { url: `/storage/${this.bucket}/upload?path={path}`, method: "post" },
        download: { url: `/storage/${this.bucket}/download?path={path}`, method: "get" },
        get: { url: `/storage/${this.bucket}/get?path={path}`, method: "get" },
        move: { url: `/storage/${this.bucket}/move`, method: "post" },
        mkdir: { url: `/storage/${this.bucket}/mkdir?path={path}`, method: "post" },
        delete: { url: `/storage/${this.bucket}/delete?path={path}`, method: "post" },
        invalidate: { url: `/storage/${this.bucket}/invalidate`, method: "post" },
        lastFlush: { url: `/storage/${this.bucket}/lastFlush`, method: "get" },
      };
    }
  }
}
</script>

<style lang="scss">
.browser-wrapper {
  height: 92.5%;

  > div {
    height: 100%;

    > div {
      height: 100%;
    }
  }

  .v-toolbar__content > .v-menu {
    display: block;
  }
}
</style>