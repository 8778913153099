import Vue from 'vue';
import Vuex from 'vuex';
import Cookies from 'js-cookie';

Vue.use(Vuex);

const userJSON = Cookies.get('user');

export default new Vuex.Store({
  state: {
    user: userJSON ? JSON.parse(userJSON) : null,
    buckets: ['gc-game-translations', 'dummy-bucket-3'],
    bucket: 'dummy-bucket-3',
    theme: Cookies.get('theme') || 'light',
    tools: [
      { code: 'buckMan', name: 'AWS Bucket Manager', icon: 'mdi-cube' }
    ],
    tool: 'buckMan'
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
      Cookies.set('user', JSON.stringify(user), { expires: 1 });
    },
    setBucket(state, bucket) {
      state.bucket = bucket;
    },
    toggleTheme(state) {
      state.theme = state.theme === 'light' ? 'dark' : 'light';
      Cookies.set('theme', state.theme);
    }
  }
});
