<template>
  <v-toolbar flat dense>
    <v-toolbar-items>
      <v-menu offset-y v-if="buckets.length > 1">
        <template v-slot:activator="{ on }">
          <v-btn icon class="storage-select-button mr-3" v-on="on">
            <v-icon>mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list class="storage-select-list">
          <v-list-item
              v-for="(item, index) in buckets"
              :key="index"
              :disabled="item === bucket"
              @click="setBucket(item)"
          >
            <v-list-item-title>{{ item }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn text :input-value="path === '/'" @click="changePath('/')">
        {{ bucket }}
      </v-btn>
      <template v-for="(segment, index) in pathSegments">
        <v-icon :key="index + '-icon'">mdi-chevron-right</v-icon>
        <v-btn
            text
            :input-value="index === pathSegments.length - 1"
            :key="index + '-btn'"
            @click="changePath(segment.path)"
        >{{ segment.name }}</v-btn>
      </template>
    </v-toolbar-items>
    <div class="flex-grow-1"></div>

    <template v-if="$vuetify.breakpoint.smAndUp">
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="invalidate" title="Flush CDN cache">
            <v-icon>mdi-toilet</v-icon>
          </v-btn>
        </template>
        <span>{{ sinceLastFlush }}</span>
      </v-tooltip>
      <v-menu :close-on-content-click="false" :nudge-width="200" offset-y>
        <template v-slot:activator="{ on }">
          <v-btn v-if="path && isFolder" icon v-on="on" title="Create Folder">
            <v-icon>mdi-folder-plus-outline</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-text>
            <v-text-field label="Name" v-model="newFolderName" hide-details autofocus></v-text-field>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn @click="newFolderPopper = false" depressed>Cancel</v-btn>
            <v-btn
                color="success"
                :disabled="!newFolderName"
                depressed
                @click="mkdir"
            >Create Folder</v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
      <v-btn v-if="path && isFolder" icon @click="$refs.inputUpload.click()" title="Upload Files">
        <v-icon>mdi-upload</v-icon>
        <input v-show="false" ref="inputUpload" type="file" multiple @change="upload" />
      </v-btn>
      <v-btn v-if="!isRoot" icon title="Download" @click="download(path)">
        <v-icon>mdi-download</v-icon>
      </v-btn>
      <v-btn v-if="!isRoot && !isFolder" icon title="Rename" @click="$emit('rename', path);">
        <v-icon>mdi-form-textbox</v-icon>
      </v-btn>
      <v-btn v-if="!isRoot && !isFolder" icon title="Move" @click="$emit('move', path);">
        <v-icon>mdi-folder-arrow-left-right</v-icon>
      </v-btn>
      <v-btn v-if="!isRoot" icon title="Delete" @click="$emit('delete', path);">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </template>
  </v-toolbar>
</template>

<script>
import { mapState } from 'vuex';
import * as moment from 'moment';

export default {
  props: {
    storages: Array,
    storage: String,
    path: String,
    endpoints: Object,
    axios: Function
  },
  data() {
    return {
      newFolderPopper: false,
      newFolderName: '',
      lastFlush: '',
      sinceLastFlush: 'loading..'
    };
  },
  computed: {
    ...mapState([
      'bucket', 'buckets'
    ]),
    isFolder() {
      return this.path[this.path.length - 1] === '/';
    },
    isRoot() {
      return this.path.length <= 1;
    },
    pathSegments() {
      let path = "/";
      let segments = this.path.split("/").filter(item => item);

      segments = segments.map((item, index) => {
        path += item + (index < segments.length - 1 || this.isFolder ? "/" : "");
        return { name: item, path };
      });

      return segments;
    }
  },
  methods: {
    setBucket(bucket) {
      this.$store.commit('setBucket', bucket);
      this.$emit('path-changed', '/');
    },
    changePath(path) {
      this.$emit('path-changed', path);
    },
    async download(path) {
      this.$emit('loading', true);

      const url = this.endpoints.download.url
          .replace(new RegExp('{storage}', 'g'), this.storage)
          .replace(new RegExp('{path}', 'g'), path);

      const res = await this.axios.request({ url, method: this.endpoints.download.method || 'get' });

      window.location = (this.isFolder ? process.env.VUE_APP_BACKEND_URL : '') + res.data.url;
      this.$emit('loading', false);
    },
    async upload(event) {
      this.$emit('add-files', event.target.files);
      this.$refs.inputUpload.value = '';
    },
    async invalidate() {
      const url = this.endpoints.invalidate.url
          .replace(new RegExp('{storage}', 'g'), this.storage);

      try {
        await this.axios.request({ url, method: this.endpoints.invalidate.method || 'post' });
      } catch (e) {
        return this.$toast.open({
          message: e.response.data.message,
          type: 'error',
          position: 'top-right'
        });
      }

      this.lastFlush = moment();
      this.lastFlushCheck();

      this.$toast.open({
        message: '✓ CDN Cache flushed!',
        type: 'success',
        position: 'top-right'
      });
    },
    async mkdir() {
      this.$emit('loading', true);

      const url = this.endpoints.mkdir.url
          .replace(new RegExp('{storage}', 'g'), this.storage)
          .replace(new RegExp('{path}', 'g'), this.path + this.newFolderName);

      await this.axios.request({ url, method: this.endpoints.mkdir.method || 'post' });

      this.$emit('folder-created', this.newFolderName);
      this.newFolderPopper = false;
      this.newFolderName = '';
      this.$emit('loading', false);
    },
    lastFlushCheck() {
      this.sinceLastFlush = this.lastFlush ? `Last flush was ${this.lastFlush.fromNow()}` : 'never';
    }
  },
  async mounted() {
    const url = this.endpoints.lastFlush.url.replace(new RegExp('{storage}', 'g'), this.storage);

    const resp = await this.axios.request({url, method: this.endpoints.lastFlush.method || 'get'});
    this.lastFlush = moment(resp.data);

    setInterval(this.lastFlushCheck, 10000);
    this.lastFlushCheck();
  }
};
</script>

<style lang="scss" scoped>
.storage-select-list .v-list-item--disabled {
  background-color: rgba(0, 0, 0, 0.25);
  color: #fff;

  .v-icon {
    color: #fff;
  }
}
</style>