<template>
  <v-dialog
      v-model="dialog"
      :max-width="options.width"
      @keydown.esc="cancel"
  >
    <v-card>
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="white--text">Rename</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pa-4 text-center">Choose a new name for the file:</v-card-text>
      <v-text-field v-model="value" class="pa-6 text-center" autofocus></v-text-field>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn text @click="cancel">Cancel</v-btn>
        <v-btn depressed :color="options.color" @click="rename">Rename</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    value: null,
    options: {
      color: "error",
      width: 300,
    }
  }),
  methods: {
    open(filename, options) {
      this.value = filename;
      this.dialog = true;
      this.options = Object.assign(this.options, options);

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    rename() {
      this.resolve(this.value);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    }
  }
};
</script>